export default function reducer(state = {
    loading: false,
    showAlert: false,
    alertMessage: null,
    alertVarient: 'info',
    site_title: "",
    site_description: "",
    site_keywords: "",
    site_featured: 0,
    site_aboutus: "",
    site_privacy: "",
    site_cookie: "",
    site_disclaimer: "",
    site_guidelines: "",
    aboutus_meta_title: "",
    aboutus_meta_description: "",
    privacy_meta_title: "",
    privacy_meta_description: "",
    cookie_policy_meta_title: "",
    cookie_policy_meta_description: "",
    disclainer_meta_title: "",
    disclainer_meta_description: "",
    guidelines_meta_title: "",
    guidelines_meta_description: "",
    imageUploading: false,
    coverUploading: false,
    otherUploading: false,
    sliders: [],
    featuredCategories: [],
    policies: [],
    showRemark: false,
    remarkOnOK: null,
    remarkData: null,
    pending: {
        pending_bookings: 0,
        pending_tours: 0,
        pending_suppliers: 0,
        pending_reviews: 0
    },
    commission: 0,
    imageUploading: false,
    coverUploading: false,
    otherUploading: false,
}, action) {
    switch (action.type) {
        case "LOADIND_START": {
            return { ...state, loading: true }
        }
        case "LOADIND_END": {
            return { ...state, loading: false }
        }
        case "SHOW_ALERT": {
            return { ...state, showAlert: action.show, alertMessage: action.message, alertVarient: action.varient }
        }
        case "STORE_SITE_SETTINGS": {
            return {
                ...state, site_title: action.settings.title,
                site_description: action.settings.description,
                site_keywords: action.settings.keywords,
                site_featured: action.settings.featured,
                commission: action.settings.commission
            }
        }
        case "STORE_SITE_CONTENT": {

            return {
                ...state, site_aboutus: action.settings.aboutus,
                site_privacy: action.settings.privacy,
                site_cookie: action.settings.cookie,
                site_disclaimer: action.settings.disclainer,
                site_guidelines: action.settings.guidelines,
                aboutus_meta_title: action.settings.aboutus_meta_title,
                aboutus_meta_description: action.settings.aboutus_meta_description,
                privacy_meta_title: action.settings.privacy_meta_title,
                privacy_meta_description: action.settings.privacy_meta_description,
                cookie_policy_meta_title: action.settings.cookie_policy_meta_title,
                cookie_policy_meta_description: action.settings.cookie_policy_meta_description,
                disclainer_meta_title: action.settings.disclainer_meta_title,
                disclainer_meta_description: action.settings.disclainer_meta_description,
                guidelines_meta_title: action.settings.guidelines_meta_title,
                guidelines_meta_description: action.settings.guidelines_meta_description,
            }
        }
        case "STORE_SLIDERS": {
            return { ...state, sliders: action.slides }
        }
        case "STORE_FEATURED_CATS": {
            return { ...state, featuredCategories: action.cats }
        }
        case "STORE_POLICIES": {
            return { ...state, policies: action.policies }
        }
        case "SHOW_REMARK": {
            return { ...state, showRemark: action.status, remarkOnOK: action.onOK, remarkData: action.data }
        }
        case "STORE_PENDING": {
            return { ...state, pending: action.pending }
        }
        case "IMAGE_UPLOADING": {
            console.log(action.type);
            return { ...state, [action.varType]: action.status }
        }
    }
    return state
}
