import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import Login from '../Layouts/Login';
import Dashboard from '../Layouts/Dashboard';
import Bookings from '../Layouts/Bookings';
import Reviews from '../Layouts/Reviews';
import Tours from '../Layouts/Tours';
import BookingDetails from '../Layouts/BookingDetails';
import ToursDetails from '../Layouts/ToursDetails';
import Messages from '../Layouts/Messages';
import Suppliers from '../Layouts/Suppliers';
import UsersDetails from '../Layouts/UsersDetails';
import Account from '../Layouts/Account';
import MyProfile from '../Layouts/MyProfile';
import Admin from '../Layouts/Admin';
import MessageCreate from '../Layouts/MessageCreate';
import Deals from '../Layouts/Deals';
import CreateDiscount from '../Layouts/CreateDiscount';
import Finances from '../Layouts/Finances';
import SiteContent from '../Layouts/SiteContent';
import ViewMessage from '../Layouts/ViewMessage';
import EditActivity from '../Layouts/EditActivity';
import Review_view from '../Layouts/ReviewView';
import LocationPages from '../Layouts/LocationPages';
import LocationPagesCreate from '../Layouts/LocationPageCreate';
import TravelInspiration from '../Layouts/TravelInspiration';
import TravelInspirationCreate from '../Layouts/TravelInspirationCreate';

export default () => (
    <BrowserRouter basename="/">
        <div>
            <Route path="/" exact component={Login} />
            <Route path="/Login" exact component={Login} />
            <Route path="/Dashboard" exact component={Dashboard} />
            <Route path="/Tours" exact component={Tours} />
            <Route path="/Bookings" exact component={Bookings} />
            <Route path="/Reviews" exact component={Reviews} />
            <Route path="/ReviewView/:aid" exact component={Review_view} />
            <Route path="/Reviews2" exact component={BookingDetails} />
            <Route path="/BookingDetails/:bid" exact component={BookingDetails} />
            <Route path="/ToursDetails/:aid" exact component={ToursDetails} />
            <Route path="/Messages" exact component={Messages} />
            <Route path="/Suppliers" exact component={Suppliers} />
            <Route path="/UsersDetails/:sid" exact component={UsersDetails} />
            <Route path="/Account" exact component={Account} />
            <Route path="/MyProfile" exact component={MyProfile} />
            <Route path="/Admin" exact component={Admin} />
            <Route path="/MessageView/:mid" exact component={ViewMessage} />
            <Route path="/MessageCreate" exact component={MessageCreate} />
            <Route path="/Deals" exact component={Deals} />
            <Route path="/CreateDiscount" exact component={CreateDiscount} />
            <Route path="/CreateDiscount/:did" exact component={CreateDiscount} />
            <Route path="/Finances" exact component={Finances} />
            <Route path="/EditActivity/:aid" exact component={EditActivity} />
            <Route path="/SiteContent" exact component={SiteContent} />
            <Route path="/LocationPages" exact component={LocationPages} />
            <Route path="/LocationPagesCreate" exact component={LocationPagesCreate} />
            <Route path="/LocationPagesCreate/:pid" exact component={LocationPagesCreate} />
            <Route path="/TravelInspiration" exact component={TravelInspiration} />
            <Route path="/TravelInspirationCreate" exact component={TravelInspirationCreate} />
            <Route path="/TravelInspirationCreate/:tid" exact component={TravelInspirationCreate} />
        </div>
    </BrowserRouter>
);