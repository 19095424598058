import React, { Component, Fragment } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Drawer from './Common/Drawer'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CancelIcon from '@material-ui/icons/Cancel'
import { compose } from 'redux';
import { connect } from 'react-redux';
import AppBar from './Common/MainAppBar';
import { GetTravelInspiration, createTravelInspiration } from '../Actions/TravelInspiration';

const drawerWidth = 240;


const styles = theme => ({

  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },

  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

  fab: {
    margin: theme.spacing.unit,
  },

  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  wrap: {
    width: 480,
    margin: 'auto',
    marginTop: 100,

  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '98%'
  },
  button: {
    margin: theme.spacing.unit,
  },
  closeButton: {
    position: "absolute",
    backgroundColor: "#ffffff",
    borderRadius: "13px",
    margin: "5px",
    padding: "2px",
    cursor: "pointer",
  },
});


const mapStateToProps = (state) => {
  return {
    travel: state.travel
  }
}


const mapDispatchToProps = dispatch => ({
  GetTravelInspiration: (tid) => dispatch(GetTravelInspiration(tid)),
  createTravelInspiration: (data) => dispatch(createTravelInspiration(data))
});


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#666ad1',
      main: '#303f9f',
      dark: '#001970',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff5c8d',
      main: '#d81b60',
      dark: '#a00037',
      contrastText: '#000',
    },

    third: {
      light: '#6d6d6d',
      main: '#424242',
      dark: '#1b1b1b',
      contrastText: '#fff',
    },
  },
});

class TravelInspirationCreate extends Component {

  constructor(props) {
    super(props);

    const { tid } = this.props.match.params;

    this.state = {
      id: 0,
      title: "",
      description: "",
      uploadImage: null,
      imagePreview: null
    };


    if (tid !== undefined && tid !== null) {
      this.props.GetTravelInspiration(tid);
    }

  }


  componentWillReceiveProps = (nextProps) => {
    if(nextProps.travel.travelInspiration!==null) {
      this.setState({
        id: nextProps.travel.travelInspiration.id,
        title: nextProps.travel.travelInspiration.title,
        description: nextProps.travel.travelInspiration.description
      });
    }
    if(nextProps.travel.page === "TravelInspiration") {
      this.props.history.push('/TravelInspiration')
    }
  }

  componentDidMount() {
    document.body.style.background = "#eee";
  }

  handleChange = (event, name) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  uploadImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      this.setState({
        uploadImage: file,
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          imagePreview: reader.result, // Set the image preview URL
        });
      };
      reader.readAsDataURL(file);
    }
  }
  handleSave = () => {
    if (this.state.title === "" || this.state.description === "" || (this.state.id===0 && this.state.uploadImage === null)) {
      return false
    }
    this.props.createTravelInspiration({
      id: this.state.id,
      title: this.state.title,
      description: this.state.description,
      uploadImage: this.state.uploadImage
    })
  }
  clearImage =()=> {
    this.setState({
      uploadImage: null,
      imagePreview: null
    })
  }

  render() {
    const { classes } = this.props;

    return <Fragment>

      <AppBar title="Dashboard"></AppBar>

      <Drawer></Drawer>

      <div style={{ width: 'auto', marginLeft: drawerWidth + 0, paddingTop: 35, height: 250 }}><div>

        <Table>

          <TableBody>
            <TableRow>
              <TableCell style={{ backgroundColor: 'white', width: 500, fontWeight: 600, fontSize: 15 }}>Travel Inspiration settings</TableCell>
              <TableCell style={{ backgroundColor: 'white', width: 0, fontWeight: 600, fontSize: 15 }}>
                <Button style={{ width: 150, marginLeft: 350, background: "#f7a414" }} variant="contained" color="primary" size="small" component={Link} to="/TravelInspiration" className={classes.button}>Back</Button>
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>

        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ backgroundColor: '#f2f2f2', width: 700, fontWeight: 500, paddingTop: 25 }} component="th">
                <Paper className={classes.root} elevation={1} style={{ marginBottom: 0 }}>
                  <div style={{ marginTop: 25 }}>
                    <FormControl style={{ width: '100%' }}>
                      <TextField
                        name="deal_name"
                        id="deal_name"
                        value={this.state.title}
                        onChange={(e) => { this.handleChange(e, 'title') }}
                        variant="outlined"
                        label="Travel Inspiration Title"
                      />
                    </FormControl>
                  </div>
                  <div style={{ marginTop: 25 }}>
                    <FormControl style={{ width: '100%' }}>
                      <TextField
                        multiline
                        rows="4"
                        name="deal_name"
                        id="deal_name"
                        value={this.state.description}
                        onChange={(e) => { this.handleChange(e, 'description') }}
                        variant="outlined"
                        label="Travel Inspiration Description"
                      />

                    </FormControl>
                  </div>
                  <div style={{ marginTop: 25 }}>

                    <td >
                      {this.state.imagePreview && (
                        <div>
                          <h2>Image Preview</h2>
                          {this.props.travel.travelInspiration !== null && this.props.travel.travelInspiration.image_url !== null &&(<CancelIcon onClick={this.clearImage} className={classes.closeButton}/>)}
                          <img
                            src={this.state.imagePreview}
                            alt="Preview"
                            style={{ maxWidth: "100%", height: "300px" }}
                          />
                        </div>
                      )}
                      {this.state.imagePreview === null && this.props.travel.travelInspiration !== null && this.props.travel.travelInspiration.image_url !== null && (
                        <div>
                          <img
                            src={this.props.travel.travelInspiration.image_url}
                            alt="Preview"
                            style={{ maxWidth: "100%", height: "300px" }}
                          />
                        </div>)}
                      {this.state.image_name !== "" ? (<img src={this.state.image_name} width="100%" height="auto" />) : null}

                      <a href={this.state.full_seo_url} target="_blank">{this.state.full_seo_url}</a>
                    </td>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="file-work-logo"
                      type="file"
                      onChange={this.uploadImage}
                    />
                    <label htmlFor="file-work-logo">
                      <Button component="span" variant="contained" color="default" className={classes.button}>
                        Select Image &nbsp;
                        <CloudUploadIcon className={classes.rightIcon} />
                      </Button>
                    </label>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <Button onClick={this.handleSave} style={{ background: "#f7a414", marginLeft: '445px' }} size="small" variant="contained" color="primary" className={classes.button}  >Save</Button>
                  </div>
                </Paper>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      </div>

    </Fragment>
  }
}

TravelInspirationCreate.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(TravelInspirationCreate);
