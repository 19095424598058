import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { handleSupplierStatus, handleStatusUpdate, clearDialogSupplier } from '../../Actions/SupplierActions';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const mapStateToProps = (state) => {
    return {
        suppliers: state.Suppliers
    }
}

const mapDispatchToProps = dispatch => ({
    handleSupplierStatus: (status, data) => dispatch(handleSupplierStatus(status, data)),
    handleStatusUpdate: (data) => dispatch(handleStatusUpdate(data)),
    clearDialogSupplier: () => dispatch(clearDialogSupplier())
});

class RejectPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reference: ""
        };
    }
    componentDidUpdate () {
        if(this.props.suppliers.dialogOpen) {
            this.props.clearDialogSupplier()
            this.setState({reference: ""})
        }
    }

    handleConfirmaton = () => {
        this.props.handleStatusUpdate({
            id: this.props.suppliers.statusDialogData.id,
            type: this.props.suppliers.statusDialogData.type,
            reference: this.state.reference
        })
    }

    handleChage = (e, field) => {
        this.setState({
            [field]: e.target.value
        });
    }

    handleClose = () => {
        this.props.handleSupplierStatus(false, null)
        this.setState({reference: ""})
    }

    render() {

        return (
            <div>
                <Dialog
                    open={this.props.suppliers.statusDialogAction}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title" style={{textAlign:"center"}}>{this.props.suppliers.statusDialogData !== null ? this.props.suppliers.statusDialogData.title : null}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <div>
                                <table style={{ width: 500 }}>
                                    <tr>
                                        <td>
                                            <label>{this.props.suppliers.statusDialogData !== null ? this.props.suppliers.statusDialogData.label : null}</label> <br />
                                            <TextField
                                                id="outlined-multiline-static"
                                                onChange={(e) => this.handleChage(e, 'reference')}
                                                value={this.state.reference}
                                                multiline
                                                rows="4"
                                                width="200px"
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </td>
                                    </tr>

                                </table>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ display: 'block' }}>
                        <Button onClick={this.handleClose} style={{ color: 'orange', float: "left" }} color="primary">
                            Cancel
                        </Button>
                        <Button disabled={this.state.reference.length === 0} onClick={() => { this.handleConfirmaton(); }} style={{ color: 'orange', float: "right" }} color="primary">
                            Verify
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>);
    }

}


export default compose(connect(mapStateToProps, mapDispatchToProps))(RejectPopup);
