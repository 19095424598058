import { API_URL } from './Config';
import { startLoading, endLoading, SnackBar, handleHTTPError } from './CommonActions';
import store from '../store';
import axios from 'axios';

export function clearTravelInspiration() {
    return { type: 'CLEAR_TRAVEL_INSPIRATION_PAGE' };
}

export function storeTravelInspirations(data) {
    return { type: 'STORE_TRAVEL_INSPIRATIONS', data };
}

export function GetTravelInspirations() {

    return function (dispatch) {
        dispatch(startLoading());
        fetch(API_URL + '/api/admin/travel/inspiration/list', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
            }
        }).then(handleHTTPError)
            .then((response) => {
                dispatch(endLoading());
                response.json().then((json) => {
                    dispatch(storeTravelInspirations(json));
                    dispatch(endLoading());
                });
            }).catch((error) => {
                dispatch(endLoading());
                dispatch(SnackBar(true, 'Error loading data', 'error'));
            });

    }
}

export function selectedTravelInspiration(data) {
    return { type: 'SELECTED_TRAVEL_INSPIRATION', data };
}

export function DeleteTravelInspiration(id) {

    return function (dispatch) {
        dispatch(startLoading());
        fetch(API_URL + '/api/admin/travel/inspiration/delete?id=' + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
            }
        }).then(handleHTTPError)
            .then((response) => {
                dispatch(endLoading());
                response.json().then((json) => {
                    dispatch(GetTravelInspirations());
                    dispatch(SnackBar(true, 'Data deleted', 'success'));
                });
            }).catch((error) => {
                dispatch(endLoading());
                dispatch(SnackBar(true, 'Error deleting page', 'error'));
            });

    }
}

export function storeActiveTravelInspiration(inspiration) {
    return { type: 'STORE_ACTIVE_TRAVEL_INSPIRATION', inspiration };
}

export function GetTravelInspiration(id) {

    return function (dispatch) {
        dispatch(startLoading());
        fetch(API_URL + '/api/admin/travel/inspiration/list?id=' + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
            }
        }).then(handleHTTPError)
            .then((response) => {
                dispatch(endLoading());
                response.json().then((json) => {
                    dispatch(storeActiveTravelInspiration(json));

                });
            }).catch((error) => {
                dispatch(endLoading());
                dispatch(SnackBar(true, 'Error loading locations', 'error'));
            });

    }
}

export function redirectTravelPage(page) {
    return { type: 'REDIRECT_TRAVEL_PAGE', page };
}

export function createTravelInspiration(data) {
    return function (dispatch) {
        dispatch(SnackBar(true, 'Uploading image, please wait...', 'info'));
        var fd = new FormData();
        if (data.uploadImage !== null) {
            fd.append('file', data.uploadImage, data.uploadImage.name);
        }
        fd.append('id', data.id);
        fd.append('title', data.title);
        fd.append('description', data.description);

        axios.post(API_URL + '/api/admin/travel/inspiration/update', fd, { 
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': 'Bearer ' + store.getState().Auth.authToken.access_token
            },
        }).then((response) => {
            dispatch(endLoading());
            dispatch(redirectTravelPage("TravelInspiration"));
        }).catch((error) => {
            dispatch(endLoading());
            dispatch(SnackBar(true, 'Data saving error', 'error'));
        });
    }
}
