import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Drawer from './Common/Drawer'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { compose } from 'redux';
import { connect } from 'react-redux';
import AppBar from './Common/MainAppBar'
import Button from '@material-ui/core/Button'
import { GetTravelInspirations, DeleteTravelInspiration, clearTravelInspiration } from '../Actions/TravelInspiration';


const drawerWidth = 240;

const styles = theme => ({

    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },

    button: {
        display: 'block',
        marginTop: theme.spacing.unit * 2,
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120,
    },

    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },

    fab: {
        margin: theme.spacing.unit,
    },

    extendedIcon: {
        marginRight: theme.spacing.unit,
    },

    wrap: {
        width: 480,
        margin: 'auto',
        marginTop: 100,

    },
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,

    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '98%'
    },

    button: {
        margin: theme.spacing.unit,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },

    logoWrap: {
        textAlign: 'center',
    },
    toolbar: theme.mixins.toolbar,
});

const mapStateToProps = (state) => {
    return {
        loading: state.Common.loading,
        loggedIn: state.Auth.loggedIn,
        travel: state.travel
    }
}

const mapDispatchToProps = dispatch => ({
    clearTravelInspiration: () => dispatch(clearTravelInspiration()),
    GetTravelInspirations: () => dispatch(GetTravelInspirations()),
    DeleteTravelInspiration: (pID) => dispatch(DeleteTravelInspiration(pID))
});

class TravelInspiration extends Component {

    constructor(props) {
        super(props);

        this.props.GetTravelInspirations();
        this.props.clearTravelInspiration();
    }

    componentDidMount() {
        document.body.style.background = "#eee";
    }
    componentDidUpdate() {
        if(this.props.travel.travelInspiration!==null) {
            this.props.history.push('/TravelInspirationCreate'); 
        }
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });

    };

    handleDelete = (Pid) => {
        this.props.DeleteTravelInspiration(Pid);
    }

    render() {
        const { classes } = this.props;

        return <Fragment>

            <AppBar title="Dashboard"></AppBar>
            <Drawer></Drawer>
            <div style={{ width: 'auto', marginLeft: drawerWidth + 0, paddingTop: 35, height: 250 }}><div>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ backgroundColor: 'white', width: 500, fontWeight: 600, fontSize: 16 }}>Travel Inspiration</TableCell>
                            <TableCell style={{ backgroundColor: 'white', width: 0, fontWeight: 600, fontSize: 15 }}><Button style={{ width: 200, marginLeft: 300, background: "#f7a414" }} variant="contained" color="primary" size="small" onClick={() => window.location.href = './TravelInspirationCreate'} className={classes.button}> <b> Create </b> </Button></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ backgroundColor: '#f2f2f2', width: 700, fontWeight: 500, paddingTop: 25 }} component="th">
                                <Paper className={classes.root} elevation={1} style={{ marginBottom: 30 }}>
                                    <div className="tab-pan">
                                        <div className="tab-bok">
                                            <Table style={{ textAlign: "right" }} className={classes.table} >
                                            </Table>
                                            <div className="tab-bok">
                                                <Table className={classes.table}>

                                                    <tr>
                                                        <th style={{ width: '30%' }}><p>Title</p></th>
                                                        <th style={{ width: '50%' }}><p >Desciption</p></th>
                                                        <th align="right"><p style={{ fontWeight: 600, fontSize: 15, color: '#000' }}></p></th>
                                                    </tr>


                                                    {this.props.travel.data.map(row => (
                                                        <tr key={row.id}>
                                                            <td component="th" scope="row">
                                                                {row.title}
                                                            </td>
                                                            <td >{row.description}</td>
                                                            <td align="right">
                                                                <Button component={Link} to={"/TravelInspirationCreate/" + row.id} variant="contained" size="small" color="primary" style={{ background: "#f7a414", alignItems: "center", alignContent: 'center' }} className={classes.button}>
                                                                    &nbsp;&nbsp;<b>View</b>
                                                                </Button>
                                                                <Button onClick={() => this.handleDelete(row.id)} variant="contained" size="small" color="primary" style={{ background: "#f7a414", alignItems: "center", alignContent: 'center' }} className={classes.button}>
                                                                    &nbsp;&nbsp;<b>Delete</b>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}

                                                </Table>
                                            </div>
                                        </div></div>
                                </Paper>

                            </TableCell>

                        </TableRow>

                    </TableBody>
                </Table>
            </div>
            </div>

        </Fragment>
    }
}

TravelInspiration.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(TravelInspiration);