import React from 'react';
import PropTypes from 'prop-types';
import { withRouter} from 'react-router-dom'

import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';

import List from '@material-ui/core/List';


import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Drawer from '@material-ui/core/Drawer';

import LoansIcon from '@material-ui/icons/Work';
import ReportIcon from '@material-ui/icons/LibraryBooks';
import AccountIcon from '@material-ui/icons/Person';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BookingIcon from '@material-ui/icons/CheckCircle';
import MessagesIcon from '@material-ui/icons/Mail';
import AdminIcon from '@material-ui/icons/ContactMail';
import FinancesIcon from '@material-ui/icons/AttachMoney';
import DealsIcon from '@material-ui/icons/LocalOffer';
import ExploreIcon from '@material-ui/icons/Explore';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import RejectPop from './RejectbtnPopup';


import { Logout } from '../../Actions/AuthActions';
import { FetchPending } from '../../Actions/SettingsAction';
import {Redirect} from 'react-router-dom'
import { compose } from 'redux';
import { connect } from 'react-redux';


const drawerWidth = 240;

const styles = theme => ({
    
    wrap:{
        width:480,
        margin:'auto',
        marginTop:100,
                
    },
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width:'98%'
      },

      button: {
        margin: theme.spacing.unit,
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
      },
      drawerPaper: {
        width: drawerWidth,
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
      }, 
      toolbar: theme.mixins.toolbar,
  });



   const mapStateToProps = (state) => {
    return {
        loading : state.Common.loading,
        pending : state.Common.pending,
        loggedIn : state.Auth.loggedIn 
    }
}

const mapDispatchToProps = dispatch => ({
  Logout:()=>dispatch(Logout()),
  FetchPending:()=>dispatch(FetchPending())
});





class LeftMenu extends React.Component {

    constructor(props){
        super(props);

        this.state = {            
        };

        this.props.FetchPending();
    }
  

    gotoDashboard =()=>{
      this.props.history.push('/Dashboard');
    }    

  gotoBookings =()=>{
    this.props.history.push('/Bookings');
  }

  gotoTours = () =>{
    this.props.history.push('/Tours');
  }

  gotoMessages = () =>{
    this.props.history.push('/Messages');
  }

  gotoMyProfile = () =>{
    this.props.history.push('/MyProfile');
  }

  gotoAccount = () =>{
    this.props.history.push('/Account');
  }

  gotoSuppliers = () =>{
    this.props.history.push('/Suppliers');
  }

  gotoReviews = () =>{
    this.props.history.push('/Reviews');
  }

  gotoAdmin = () =>{
    this.props.history.push('/Admin');
  }

  gotoFinances = () =>{
    this.props.history.push('/Finances');
  }

  gotoDeals = () =>{
    this.props.history.push('/Deals');
  }
  gotoSiteContent= () =>{

    this.props.history.push('/SiteContent');
  }

  gotoLocationPages= () =>{

    this.props.history.push('/LocationPages');
  }
  gotoTravelInspiration= () =>{
    this.props.history.push('/TravelInspiration');
  }

 logoutUser = () =>{    
    this.props.history.push('/Login');
    this.props.Logout();
  }

  


  render() {
  
    const { classes } = this.props;
           

    return (
      <div className={classes.root}>
         <div className="bk-menu" >
        <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{
                          paper: classes.drawerPaper,
                        }}
                      >
                        <div className={classes.toolbar} />
                       
                        <List>
                        <ListItem button key="Dashboard" onClick={this.gotoDashboard}>
                              <ListItemIcon> <DashboardIcon /></ListItemIcon>
                              <ListItemText primary="Dashboard" />
                            </ListItem>

                            <ListItem button key="Bookings" onClick={this.gotoBookings}>
                             
                            
                              <ListItemIcon>
                              
                              <BookingIcon />
                              </ListItemIcon>
                              { this.props.pending.pending_bookings > 0 ? (<Badge className={classes.padding} color="secondary" style={{position:'absolute', right:20}}  badgeContent={this.props.pending.pending_bookings}/>):null } 
                              <ListItemText primary="Bookings" />
                               
                              </ListItem>
                                
                            


                            
                            <ListItem button key="Tours" onClick={this.gotoTours}>
                            
                              <ListItemIcon> <LoansIcon /></ListItemIcon>
                              { this.props.pending.pending_tours > 0 ? (<Badge className={classes.padding} color="secondary" style={{position:'absolute', right:20}}  badgeContent={this.props.pending.pending_tours}/>):null } 
                              <ListItemText primary="Tours & Activities" />
                              
                            </ListItem>
                            
                            {/* <ListItem button key="MyProfile" onClick={this.gotoMyProfile}>
                              <ListItemIcon> <AccountIcon /></ListItemIcon>
                              <ListItemText primary="My Profile" />
                            </ListItem> */}
                            <ListItem button key="Suppliers" onClick={this.gotoSuppliers}>
                            
                              <ListItemIcon> <AccountIcon /></ListItemIcon>
                              { this.props.pending.pending_suppliers > 0 ? (<Badge className={classes.padding} color="secondary" style={{position:'absolute', right:20}}  badgeContent={this.props.pending.pending_suppliers}/>):null } 
                              <ListItemText primary="Suppliers" />
                               
                            </ListItem>

                            <ListItem button key="Reviews" onClick={this.gotoReviews}>
                            
                              <ListItemIcon> <ReportIcon /></ListItemIcon>
                              { this.props.pending.pending_reviews > 0 ? (<Badge className={classes.padding} color="secondary" style={{position:'absolute', right:20}}  badgeContent={this.props.pending.pending_reviews}/>):null } 
                              <ListItemText primary="Reviews & Ratings" />
                              
                            </ListItem>

                            <ListItem button key="Admin" onClick={this.gotoAdmin}>
                              <ListItemIcon> <AdminIcon /></ListItemIcon>
                              <ListItemText primary="Admin Messages" />
                            </ListItem>
                            <ListItem button key="Finances" onClick={this.gotoFinances}>
                              <ListItemIcon> <FinancesIcon /></ListItemIcon>
                              <ListItemText primary="Finances" />
                            </ListItem>
                            <ListItem button key="Deals" onClick={this.gotoDeals}>
                              <ListItemIcon> <DealsIcon /></ListItemIcon>
                              <ListItemText primary="Deals & Discounts" />
                            </ListItem>
                            <ListItem button key="SiteContent" onClick={this.gotoSiteContent}>
                              <ListItemIcon> <DealsIcon /></ListItemIcon>
                              <ListItemText primary="Site Content" />
                            </ListItem>
                            <ListItem button key="LocationPages" onClick={this.gotoLocationPages}>
                              <ListItemIcon> <DealsIcon /></ListItemIcon>
                              <ListItemText primary="Location Pages" />
                            </ListItem>
                            <ListItem button key="TravelInspiration" onClick={this.gotoTravelInspiration}>
                              <ListItemIcon> <ExploreIcon /></ListItemIcon>
                              <ListItemText primary="Travel Inspiration" />
                            </ListItem>

                            <ListItem button key="Logout"  onClick={this.logoutUser}>
                              <ListItemIcon> <LogoutIcon /></ListItemIcon>
                              <ListItemText primary="Logout" />
                            </ListItem>
                        </List>
                       
                      </Drawer>
                      </div>
      </div>
   
    );
  }
}

LeftMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

LeftMenu.defaultProps = {
  currentPage: 'dashboard',
};

//export default withRouter(withStyles(styles)(LeftMenu));
export default compose(withRouter,connect(mapStateToProps, mapDispatchToProps),withStyles(styles))(LeftMenu);