import {API_CLIENT_ID, API_CLIENT_SECRET, API_URL, API_GRANT_TYPE} from './Config';
import {startLoading,endLoading, SnackBar,handleHTTPError} from './CommonActions';
import store from '../store';
import axios from 'axios';

export function GetSuppliersStausFilter(status) {
    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/suppliers?gstatus='+status,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(storeSuppliers(json));                                                     
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   

}

export function GetSuppliers(supler,cat,sts,kw ) {

    if(supler === null || supler === undefined ){
        var supler = "";
    }

    if(cat === null || cat === undefined ){
        var cat = "";
    }

    if(sts === null || sts === undefined ){
        var sts = "";
    }

    if(kw === null || kw === undefined ){
        var kw = "";
    }

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/suppliers?supler='+supler+'&cat='+cat+'&sts='+sts+'&kw='+kw,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(storeSuppliers(json));                                                     
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }   

}



export function storeSuppliers(list) {
    return {
        type: 'STORE_SUPPLIERS',
        list: list
    }
}


export function GetSupplier(supplierID) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/suppliers?sid='+supplierID,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(storeActiveSupplier(json));                                                     
              }); 
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not load data','error'));
        });
    }  

}

export function UpdateField(data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/suppliers/field/update',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify({
                field:data.field,
                value:data.value,
                sid:data.sid
            }),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(GetSupplier(data.sid)); 
                  dispatch(SnackBar(true,'Your profie was updated','success'));                                    
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not update profile','error'));
        });
    }   

}

export function UpdateRemoveAttachment(field,id) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/suppliers/docs?sid='+id+'&field='+field,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(GetSupplier(id));
                  dispatch(SnackBar(true,'Status updated','success'));                                                     
              }); 
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not update data','error'));
        });
    }  

}

export function UpdateFieldStatus(status,field,id) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/suppliers/review?sid='+id+'&status='+status+'&field='+field,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(GetSupplier(id));
                  dispatch(SnackBar(true,'Status updated','success'));                                                     
              }); 
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not update data','error'));
        });
    }  

}

export function UpdateStatus(status,sid) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/suppliers/update?sid='+sid+'&status='+status,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(GetSupplier(sid));
                  dispatch(SnackBar(true,'Status updated','success'));                                                     
              }); 
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not update data','error'));
        });
    }  

}

export function UpdateCommision(rate,sid) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/suppliers/commission?sid='+sid+'&rate='+rate,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(GetSupplier(sid));
                  dispatch(SnackBar(true,'Commission updated','success'));                                                     
              }); 
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not update data','error'));
        });
    }  

}




export function UpdateBanned(till,sid) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/suppliers/ban?sid='+sid+'&till='+till,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  dispatch(GetSupplier(sid));
                  dispatch(SnackBar(true,'Supplier updated','success'));                                                     
              }); 
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not update data','error'));
        });
    }  

}

export function storeActiveSupplier(supplier) {
    return {
        type: 'STORE_ACTIVE_SUPPLIERS',
        user: supplier
    }
}


export function LockActiveSupplier() {
    return {
        type: 'LOCK_ACTIVE_SUPPLIER',        
    }
}


export function updateActivity(data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/supplier/activities/update',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());                   
                  //dispatch(fetchUser(json)); 
                  dispatch(SnackBar(true,'Data saved','success'));                                    
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }   

}


export function uploadPicture(file,aid){

    return function(dispatch) {
        dispatch(SnackBar(true,'Uploading image, please wait...','info'));

        var fd = new FormData();
        fd.append('file',file,file.name);
        fd.append('aid',aid);

        axios.post(API_URL+'/api/supplier/activities/upload',fd,{            
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },           
        }).then((response)=>{
              dispatch(endLoading());
              dispatch(GetSupplier(aid));
              dispatch(SnackBar(true,'Your profie was updated','success'));
        }).catch((error)=>{
            console.log(error)              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could upload image','error'));
        });
    }
}

export function handleSupplierStatus(status, data) {
    return { type: 'HANDLE_SUPPLIER_STATUS',status, data }
}

export function supplierPageRedirect(bool) {
    return { type: 'SUPPLIER_PAGE_REDIRECT',bool }
}

export function clearDialogSupplier() {
    return { type: 'CLEAR_DIALOG_SUPPLIER' }
}

export function handleStatusUpdate(data) {

    return function(dispatch) {
        dispatch(startLoading());
        fetch(API_URL+'/api/admin/suppliers/status/update',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Authorization' :'Bearer '+store.getState().Auth.authToken.access_token
            },
            body:JSON.stringify(data),
        }).then(handleHTTPError)
        .then((response)=>{
              dispatch(endLoading());
              response.json().then((json)=>{
                  dispatch(endLoading());     
                  dispatch(SnackBar(true,json.message,'success'));                                    
                  dispatch(handleSupplierStatus(false, null));    
                  if(data.type === "delete") {      
                    dispatch(supplierPageRedirect(true));  
                  } else {
                    dispatch(GetSupplier(data.id));
                  }                        
              });
        }).catch((error)=>{              
          dispatch(endLoading());            
          dispatch(SnackBar(true,'Could not save data','error'));
        });
    }   

}