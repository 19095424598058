export default function reducer(state = {
    data: [],
    travelInspiration: null,
    page: null
}, action) {
    switch (action.type) {
        case "STORE_TRAVEL_INSPIRATIONS": {
            return { ...state, data: action.data }
        }
        case "CLEAR_TRAVEL_INSPIRATION_PAGE": {
            return { ...state, travelInspiration: null, page: null }
        }
        case "STORE_ACTIVE_TRAVEL_INSPIRATION": {
            return { ...state, travelInspiration: action.inspiration }
        }
        case "REDIRECT_TRAVEL_PAGE": {
            return { ...state, page: action.page }
        }
    }

    return state;
}
